.modal-home {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-home:focus{
    outline: none;
}

.modal-overlay-home {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(103, 103, 103, 0.70);;
    z-index: 1000;
}

.modal-content-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 80%;
    /*height: 90%;*/
    overflow-y: auto;
    border-radius: 10px;
}
