.modal-home {
    position: absolute;
    border-radius: 10px;
}

.modal-home:focus{
    outline: none;
}

.modal-overlay-home {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(232, 232, 232, 0.7);
    z-index: 1000;
}

.modal-overlay-platform {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.connected-container{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    
}

.remove-account{
    color: #4485f4;
    cursor: pointer;
}

.modal-content-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 480px;
    height: 185px;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
}

.h1 {
    color: #256CE8;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.desc {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 16px;
    margin-bottom: 40px;
}

.btn-ctn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}