.modal-calibrate {
    position: absolute;
    border-radius: 10px;
}

.modal-calibrate:focus{
    outline: none;
}

.modal-overlay-calibrate {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(103, 103, 103, 0.70);
    z-index: 1000;
}

.modal-content-calibrate {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 500px;
    height: 250px;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
}

.calibrate-heading-ctn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.calibrate-heading {
    color: #256CE8;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.calibrate-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.calibrate-label {
    color: #101010;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.calibrate-input-ctn {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calibrate-input {
    overflow: hidden;
    color: #101010;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */

    height: 28px;
    padding: 10px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #101010;
    background: #FFF;
    outline: none;
}

.calibrate-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.name-error {
    color: #E13737;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}